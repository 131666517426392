@import '../node_modules/modern-css-reset/dist/reset.min.css';
@font-face {
  font-family: "Euclid Circular A";
  src: url(fonts/EuclidCircularA-Light.otf);
}
h3 {
  font-size: 10vw;
  margin-left: 60vw;
  margin-top: 3vw;
}
.contact {
  display: inline-block;
  padding-left: 10vw;
  padding-bottom: 1vw;
  padding-top: 2vw;
  font-size: 1vw;
  color: grey;
}
.contact1 {
  display: inline-block;
  padding-left: 23vw;
  padding-bottom: 1vw;
  padding-top: 2vw;
  font-size: 1vw;
  color: grey;
}
.contact2 {
  display: inline-block;
  padding-left: 25vw;
  padding-bottom: 1vw;
  padding-top: 2vw;
  font-size: 1vw;
  color: grey;
}
h2 {
  font-size: 2vw;
  text-align: center;
  line-height: 4.2vw;
}
.droite {
  font-size: 2vw;
  margin-top: 1vw;
}
a,
a:link,
a:visited {
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 1.7vw;
  margin-top: 2vw;
  display: inline-block;
}
a:hover {
  color: grey;
}
.menu {
  width: 100%;
}
a.boutonprincipal {
  font-size: 7vw;
  text-align: center;
  padding-left: 30vw;
  padding-right: 10vw;
  padding-top: 5vw;
  padding-bottom: 3vw;
  line-height: 6.5vw;
}
h1 {
  font-size: 1.2vw;
}
img:not([alt]) {
  filter: none;
}
body {
  font-family: "Euclid Circular A", sans-serif;
}
header {
  display: flex;
  justify-content: space-between;
  padding-top: 2vw;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 7vw;
}
header.cercle {
  width: 100px;
  height: 100px;
  background: black;
  border-radius: 50%;
}
header.cercle h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 1s;
}
header.cercle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
main {
  padding-bottom: 10vw;
  padding-top: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
}
main.projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 0vw;
  grid-column-gap: 1.5vw;
}
main.projects .project {
  position: relative;
}
main.projects .project img {
  height: calc((100vw - 14vw) / 3);
  width: 100%;
  object-fit: cover;
  transition: filter 0.2s ease-in-out;
  filter: grayscale(100%);
  /* FF 35+ */
}
main.projects .project p {
  position: absolute;
  font-size: 1.5vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  margin: 0;
  text-align: center;
  opacity: 0;
}
main.projects .project:hover {
  cursor: pointer;
}
main.projects .project:hover a {
  color: black;
}
main.projects .project:hover p {
  opacity: 1;
  transition: 1s;
}
main.projects .project:hover img {
  filter: grayscale(0%);
  transition: 1s;
  /* FF 35+ */
}
main.apropos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3vw;
}
main.apropos p {
  margin-left: 0;
  margin-top: 3vw;
  margin-right: 4vw;
}
main.apropos .portrait {
  border-radius: 50%;
  width: 30vw;
  height: 30vw;
  margin-left: 6vw;
  margin-top: 3vw;
}
main.apropos .portrait .cv {
  padding-top: 5vw;
}
main.apropos .portrait img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.col p {
  font-size: 1.4vw;
  text-align: center;
  padding-left: 30vw;
  padding-right: 30vw;
  padding-bottom: 2vw;
  line-height: 2vw;
}
.carousel {
  position: relative;
}
.carousel .prev {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  font-size: 3rem;
  cursor: pointer;
}
.carousel .prev:hover {
  color: grey;
}
.carousel .next {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  font-size: 3rem;
  cursor: pointer;
}
.carousel .next:hover {
  color: grey;
}
.carousel .slide {
  height: 70vh;
  width: 100%;
}
.carousel .slide img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.carousel h1 {
  font-size: 10rem;
}
@media only screen and (orientation: portrait) {
  .col p {
    font-size: 2.5vw;
    text-align: center;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-bottom: 2vw;
    line-height: 3vw;
  }
  .carousel {
    position: relative;
  }
  .carousel .prev {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    cursor: pointer;
  }
  .carousel .prev:hover {
    color: grey;
  }
  .carousel .next {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    cursor: pointer;
  }
  .carousel .next:hover {
    color: grey;
  }
  h2 {
    font-size: 3vw;
    text-align: center;
    line-height: 4.2vw;
  }
  a,
  a:link,
  a:visited {
    color: black;
    text-decoration: none;
    text-align: center;
    font-size: 2.7vw;
    margin-top: 2vw;
    display: inline-block;
  }
  a:hover {
    color: grey;
  }
  .menu {
    width: 100%;
  }
  a.boutonprincipal {
    font-size: 7vw;
    text-align: center;
    padding-left: 30vw;
    padding-right: 10vw;
    padding-top: 5vw;
    padding-bottom: 3vw;
    line-height: 6.5vw;
  }
}
